export default {
    brand: 'i-mynaui-letter-b-hexagon',
    model: 'i-mynaui-letter-m-hexagon',
    version: 'i-mynaui-letter-v-hexagon',
    bfa: 'i-mdi-factory',
    user: 'i-heroicons-user',
    company: 'i-heroicons-building-office',
    adminUser: 'i-mdi-shield-account-outline',
    asset: 'i-heroicons-squares-plus',
    dossier: 'i-heroicons-document-text',
    assetType: 'i-mdi-plane-train',
    auctionHistoryLogType: 'i-heroicons-clipboard-document-list',
    auction: 'i-streamline-justice-hammer',
    auctionPlanner: 'i-heroicons-calendar-days',
    auctionRelist: 'i-heroicons-square-2-stack',
    loading: 'i-svg-spinners-270-ring-with-bg',
    scrapping: 'i-mdi-recycle',
    onSiteDataCollected: 'i-streamline-database-check',
    emailVerified: 'i-mdi-email-check-outline',
    bailVoid: 'i-mdi-bank-remove',
    bailCapture: 'i-mdi-bank-check',
};
